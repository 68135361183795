<template>
  <div class="w-full" v-loading="loading">
    <div class="pb-2">
      <div class="flex flex-row justify-between">
        <div class="title">Report Equipment</div>
        <div v-if="! disabled">
          <el-button plain type="primary" size="mini" @click="add">Add</el-button>
        </div>
      </div>
      
      <div class="w-full pb-4" style="overflow-x: auto;">
        <el-table :data="reportEquipment" style="min-width: 380px; width: 100%">
          <el-table-column :label="$t('table.equipment')" width="150">
            <template slot-scope="scope">
              <el-select size="mini" v-model="scope.row.equipmentId" :disabled="disabled"
                @change="(val) => handleChange(val, scope.$index)"
              >
                <el-option 
                  v-for="item in equipmentList" 
                  :key="item.formId" 
                  :label="item.formDescription"
                  :value="item.formId"
                />
              </el-select>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$t('table.remarks')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remarks" :disabled="disabled" rows="1" type="textarea"></el-input>
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('table.reportIssue')">
            <template slot-scope="scope">
              <div class="w-full">
                <div class="inline-block" style="width: calc(100% - 30px);">
                  <el-input v-model="scope.row.reportIssue" :disabled="disabled" size="mini"></el-input>
                </div>
                <div class="inline-block text-right" style="width: 30px;">
                  <i class="el-icon-circle-close fs-15" :class="disabled ? '' : 'cursor-pointer'" 
                    style="color: red" @click="() => remove(scope.$index)">
                  </i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="w-full" style="overflow-x: hidden;">
        <div class="title">Pass Equipment Report</div>
        <el-table :data="passReportEquipment" style="min-width: 200px; width: 100%" v-loading="equipmentReportsLoading">
          <el-table-column :label="$t('table.equipment')" prop="equipmentName" width="150"/>
          <!-- <el-table-column :label="$t('table.remarks')" prop="remarks" width="150"/> -->
          <el-table-column :label="$t('table.reportIssue')" prop="reportIssue" width="150"/>
          <el-table-column :label="$t('table.reportedOn')" prop="reportedTime" width="150"/>
          <el-table-column fixed="right" label="Status" width="150">
            <template slot-scope="scope">
              <div v-if="checkStatus(scope.row.status)" class="text-center">
                <el-button  plain type="primary" size="mini" @click="() => handleResolve(scope.row.nominationId, scope.row.equipmentId)">Mark as Resolved</el-button>
              </div>
              <div v-else class="green">{{ $t('table.resolvedOn') }} {{  scope.row.resolvedOn  }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { 
  pilotReportEquipment, 
  pilotSaveReportEquipment, 
  getEquipmentReports, 
  pilotResolveReportEquipment 
} from "@/services/form"
export default {
  name: "ReportEquipment",
  props: {
    id: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      equipmentReportsLoading: false,
      reportEquipment:[],
      passReportEquipment: [],
      equipmentList: [],
    }
  },
  async created() {
    await this.getDetail()
    await this.getPassEquipmentReports()
    if(this.reportEquipment.length == 0) {
      this.add()
    }
  },
  mounted() {
    this.$emit('save', this.save)
  },
  methods: {
    add() {
      this.reportEquipment.push({ equipmentId: null, equipmentName: null, remarks: null, reportIssue: null })
    }, 
    remove(index) {
      if( ! this.disabled ) {
        this.reportEquipment.splice(index, 1);
      }
    },
    async getDetail() {
      await pilotReportEquipment({ nominationId: this.id }).then(res => {
        this.reportEquipment = res.data.reportEquipmentList
        this.equipmentList = res.data.equipmentList
      })
    },
    handleChange(val, index) {
      const equipment = this.equipmentList.find(item => item.formId == val)
      this.reportEquipment[index].equipmentName = equipment?.formDescription
    },
    async save() {
      let reportEquipment = this.reportEquipment.filter(item => item.equipmentId != null)
      if(reportEquipment.length == 0) {
        return false
      }

      this.loading = true
      const data = {
        nominationId: this.id,
        reportEquipment: reportEquipment,
      }

      await pilotSaveReportEquipment(data).then(async res=>{
        await this.getDetail()
        this.$message.success('Save Report Equipment Success')
      }).catch(()=>{
      }).finally(() => {
        this.loading = false
      })
    },
    async getPassEquipmentReports() {
      const params = {
        nominationId: this.id,
        pageNum: 1,
        pageSize: 99999
      }
      await getEquipmentReports(params).then(res => {
        this.passReportEquipment = res.data.table.rows
      })
    },
    async handleResolve(nominationId, equipmentId) {
      this.equipmentReportsLoading = true
      await pilotResolveReportEquipment( {nominationId, equipmentId} ).then(async res => {
        this.$message.success('The Equipment Report has been resolved')
        await this.getPassEquipmentReports()
      }).finally(() => {
        this.equipmentReportsLoading = false
      })
    },
    checkStatus(status) {
      return status == '0'
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-family: 'Roboto';
  color: black;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
}
::v-deep.el-table::before {
  height: 0px;
}
::v-deep.el-table thead tr {
  background-color: #F4F8FB;
}
::v-deep.el-table th.el-table__cell.is-leaf {
  background-color: transparent;
  font-size: 12px;
  font-weight: 800;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell {
  font-size: 11px;
  font-weight: 600;
  color: #1D1B20;
  border: 1px solid #82889C;
  border-bottom: none;
}
::v-deep.el-table td.el-table__cell .cell {
  font-size: 11px;
  font-weight: 600;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell:first-child {
  border-right: none;
}
::v-deep.el-table td.el-table__cell:last-child {
  border-left: none;
}
::v-deep.el-table tr:last-child td.el-table__cell {
  border-bottom: 1px solid #82889C;
}
.green{
  color: #1CBB19;
}
</style>